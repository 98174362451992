<template>
  <div class="page-header">
    <div class="title">浙江省文物考古研究所动物骨骼标本数据库</div>
    <div class="flex-center">
      <div>
        <el-avatar :size="46" :icon="UserFilled" />
        <span class="username">{{ user }}</span>
      </div>
      <el-dropdown>
        <el-icon color="#909399">
          <arrow-down />
        </el-icon>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="onClickLogout">注销</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { ArrowDown, UserFilled } from "@element-plus/icons-vue";
import { TokenUtils } from "@/assets/js/TokenUtils";
import { useRouter } from "vue-router";

const router = useRouter();

const user = TokenUtils.getUsername();

const onClickLogout = () => {
  TokenUtils.clear();
  router.push("/login");
};
</script>

<style lang="less" scoped>
.page-header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222222;
  padding-left: 41px;
  padding-right: 70px;

  .title {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 33px;
  }

  .username {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
    margin-left: 9px;
    margin-right: 21px;
  }
}
</style>
