import { createRouter, createWebHashHistory } from "vue-router";
import { UserApi } from "@/api/UserApi";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    meta: { pageHeader: true },
    component: () => import("@/views/IndexView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { title: "登录" },
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/view/three/:type/:fileMd5",
    name: "ThreeView",
    meta: { title: "模型浏览" },
    props: true,
    component: () => import("@/views/ThreeView"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.path !== "/login") {
    try {
      await UserApi.getUserInfo();
    } catch (error) {
      if (error.status === 401) {
        next("/login");
        return;
      }
    }
  }
  next();
});

export default router;
