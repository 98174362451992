import { get, post } from "@/api/Base";

export class UserApi {
  static getUserInfo() {
    return get("/user/info");
  }

  static login(username, password) {
    return post("/user/login", {}, { username, password });
  }

  static changePassword(newPassword) {
    return post("/user/password/change", {}, { newPassword });
  }

  static page(page, size) {
    return get("/user/page", { page, size });
  }

  static resetPassword(accountId, password) {
    return post(`/user/password/reset/${accountId}`, {}, { password });
  }

  static create({ username, password, role, platform }) {
    return post("/user/create", {
      username,
      password,
      role,
      platform,
    });
  }

  static remove(accountId) {
    return post(`/user/remove/${accountId}`);
  }
}
