<template>
  <el-card class="transfer-panel" v-show="visible">
    <template #header>
      <div>
        <span>传输面板</span>
        <el-icon class="float-right cursor-pointer" @click="hide">
          <close />
        </el-icon>
      </div>
    </template>
    <el-table :data="fileList" height="250" :fit="false">
      <el-table-column prop="fileName" label="文件名" width="180" />
      <el-table-column prop="progress" label="进度条" width="180">
        <template #default="{ row }">
          <el-progress :percentage="row.progress"></el-progress>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="传输类型" width="120" />
      <el-table-column prop="status" label="状态" width="120" />
      <el-table-column label="操作" width="120">
        <template #default="{ row }">
          <el-button
            type="danger"
            class="cancel-button"
            size="small"
            @click="cancel(row)"
            >取消</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Close } from "@element-plus/icons-vue";

export default {
  name: "file-transfer-panel",
  components: { Close },
  computed: {
    ...mapState("FileTransferPanel", ["visible", "fileList"]),
  },
  methods: {
    ...mapMutations("FileTransferPanel", ["hide"]),
    ...mapActions("FileTransferPanel", ["cancelDownload"]),
    cancel(row) {
      this.cancelDownload({ cancelToken: row.cancel, fileId: row.fileId });
    },
  },
};
</script>

<style lang="less" scoped>
.transfer-panel {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2000;
}
</style>
