import axios from "axios";
import { ObjectUtils } from "pangju-utils";
import { ElNotification } from "element-plus";
import { TokenUtils } from "@/assets/js/TokenUtils";

const apiConfig = {
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  // 请求超时时间设置
  timeout: 10 * 1000,
  crossDomain: true,
};

// 创建请求实例
const apiAxios = axios.create(apiConfig);
const CancelToken = axios.CancelToken;

let timeoutTimes = 0;

apiAxios.interceptors.request.use(
  (requestConfig) => {
    const reqConfig = { ...requestConfig };

    if (!reqConfig.url) {
      throw new Error({
        source: "axiosInterceptors",
        message: "request need url",
      });
    }

    reqConfig.headers.platform = "BONE";
    reqConfig.headers["x-access-token"] = TokenUtils.getToken();

    if (reqConfig.method === "post") {
      let hasFile = false;
      for (let key of Object.keys(reqConfig.data)) {
        if (typeof reqConfig.data[key] === "object") {
          const item = reqConfig.data[key];
          if (
            item instanceof FileList ||
            item instanceof File ||
            item instanceof Blob
          ) {
            hasFile = true;
            break;
          }
        }
      }

      if (hasFile) {
        const formData = new FormData();
        Object.keys(reqConfig.data).forEach((key) => {
          if (ObjectUtils.nonNull(reqConfig.data[key])) {
            formData.append(key, reqConfig.data[key]);
          }
        });
        reqConfig.data = formData;
      }
    }

    return reqConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiAxios.interceptors.response.use(
  (res) => {
    timeoutTimes = 0;

    if (["blob", "arraybuffer", "stream"].includes(res.config.responseType)) {
      return res;
    }

    const resData = res.data;
    if (resData.code < 0) {
      ElNotification.error(resData.msg);
      return false;
    }
    return resData.results;
  },
  async (error) => {
    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      if (timeoutTimes >= 10) {
        timeoutTimes = 0;
        ElNotification.warning("请求超时, 请稍后重试");
        return;
      }
      ++timeoutTimes;
      return apiAxios(error.config);
    }
    return Promise.reject(error.response);
  }
);

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 * @param headers
 */
export function post(url, data = {}, params = {}, headers = {}) {
  return apiAxios.post(url, data, { params, headers });
}

/**
 * @param {string} url
 * @param {object} params
 * @param headers
 */
export function get(url, params = {}, headers = {}) {
  return apiAxios.get(url, { params, headers });
}

/**
 * @param {string} url
 * @param {object} params
 * @param onDownloadProgress
 * @param cancelToken
 */
export function getDownload(
  url,
  params = {},
  onDownloadProgress = () => {},
  cancelToken
) {
  return apiAxios.get(url, {
    params,
    responseType: "blob",
    onDownloadProgress,
    cancelToken: cancelToken.token,
    timeout: 1000 * 60 * 60,
  });
}

/**
 * @param {string} url
 * @param {object} data
 * @param onDownloadProgress
 * @param cancelToken
 */
export function postDownload(
  url,
  data = {},
  onDownloadProgress = () => {},
  cancelToken
) {
  return apiAxios.post(url, data, {
    responseType: "blob",
    onDownloadProgress,
    cancelToken: cancelToken.token,
    timeout: 1000 * 60 * 60,
  });
}

export function createCancelToken() {
  return CancelToken.source();
}
