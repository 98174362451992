import { nanoid } from "nanoid";
import { createCancelToken, getDownload, postDownload } from "@/api/Base";
import fileDownload from "js-file-download";
import { ObjectUtils } from "pangju-utils";
import { getSignatureUrl } from "@/assets/js/ApiSignature";

export const FileTransferPanel = {
  namespaced: true,
  state() {
    return {
      visible: false,
      fileList: [],
    };
  },
  getters: {
    getById: (state) => (id) => {
      return state.fileList.find((file) => file.fileId === id);
    },
  },
  mutations: {
    show(state) {
      state.visible = true;
    },
    hide(state) {
      state.visible = false;
    },
    add(state, file) {
      state.fileList.push(file);
    },
    removeById(state, id) {
      state.fileList = state.fileList.filter((file) => file.fileId !== id);
      if (state.fileList.length === 0) {
        state.visible = false;
      }
    },
  },
  actions: {
    removeById(context, id) {
      setTimeout(() => {
        context.commit("removeById", id);
        if (context.state.fileList.length === 0) {
          context.commit("hide");
        }
      }, 3000);
    },
    visiblePanel(context) {
      if (context.state.visible) {
        context.commit("hide");
      } else {
        context.commit("show");
      }
    },
    uploadFile(context, data) {
      context.commit("show");

      const file = context.getters.getById(data.fileId);
      if (ObjectUtils.nonNull(file)) {
        file.progress = data.progress;
        if (data.progress === 100) {
          file.status = "上传完成";
          context.dispatch("removeById", data.fileId).then(() => {});
        } else {
          file.status = "上传中";
        }
      } else {
        data.type = "上传";
        if (data.progress === 100) {
          data.status = "上传完成";
          context.dispatch("removeById", data.fileId).then(() => {});
        } else {
          data.status = "准备上传";
        }
        context.commit("add", data);
      }
    },
    async downloadFile(context, { fileMd5, fileName }) {
      context.commit("show");

      const cancelToken = createCancelToken();
      const fileId = nanoid();
      context.commit("add", {
        fileId: fileId,
        fileName: fileName,
        progress: 0,
        type: "下载",
        status: "准备下载",
        cancel: cancelToken,
      });
      const file = context.getters.getById(fileId);

      getDownload(
        getSignatureUrl(
          `${process.env.VUE_APP_BASE_FILE_URL}/file/download/${fileMd5}?placeholder=true`
        ),
        {},
        (progress) => {
          const downProgress = Math.round(
            (100 * progress.loaded) / progress.total
          );
          file.progress = downProgress;
          if (downProgress === 100) {
            file.status = "下载完成";
            context.dispatch("removeById", fileId).then(() => {});
          } else {
            file.status = "下载中";
          }
        },
        cancelToken
      ).then((res) => {
        fileDownload(res.data, fileName);
      });
    },
    async exportExcel(context, condition) {
      context.commit("show");

      const cancelToken = createCancelToken();
      const fileId = nanoid();
      context.commit("add", {
        fileId: fileId,
        fileName: fileId + ".xlsx",
        progress: 0,
        type: "下载",
        status: "正在生成",
        cancel: cancelToken,
      });
      const file = context.getters.getById(fileId);

      postDownload(
        `${process.env.VUE_APP_BASE_URL}/jade/article/export`,
        condition,
        (progress) => {
          const downProgress = Math.round(
            (100 * progress.loaded) / progress.total
          );
          file.progress = downProgress;
          if (downProgress === 100) {
            file.status = "下载完成";
            context.dispatch("removeById", fileId).then(() => {});
          } else {
            file.status = "下载中";
          }
        },
        cancelToken
      ).then((res) => {
        fileDownload(res.data, file.fileName);
      });
    },
    async exportStatistics(context, { startDate, endDate }) {
      context.commit("show");

      const cancelToken = createCancelToken();
      const fileId = nanoid();
      context.commit("add", {
        fileId: fileId,
        fileName: fileId + ".xlsx",
        progress: 0,
        type: "下载",
        status: "正在生成",
        cancel: cancelToken,
      });
      const file = context.getters.getById(fileId);

      getDownload(
        `${process.env.VUE_APP_BASE_URL}/jade/statistics/export`,
        { startDate, endDate },
        (progress) => {
          const downProgress = Math.round(
            (100 * progress.loaded) / progress.total
          );
          file.progress = downProgress;
          if (downProgress === 100) {
            file.status = "下载完成";
            context.dispatch("removeById", fileId).then(() => {});
          } else {
            file.status = "下载中";
          }
        },
        cancelToken
      ).then((res) => {
        fileDownload(res.data, file.fileName);
      });
    },
    cancelDownload(context, { cancelToken, fileId }) {
      cancelToken.cancel("暂停下载");
      context.commit("removeById", fileId);
      if (context.state.fileList.length === 0) {
        context.commit("hide");
      }
    },
  },
};
