import Crypto from "crypto-js";

export function getApiSignature(url) {
  return Crypto.SHA1(
    `${process.env.VUE_APP_API_APP_ID}&${
      process.env.VUE_APP_API_APP_SECRET_KEY
    }&${encodeURIComponent(url)}`
  );
}

export function getSignatureUrl(url) {
  return `${url}${url.includes("?") ? "&" : "?"}apiAppId=${
    process.env.VUE_APP_API_APP_ID
  }&apiSignature=${getApiSignature(url)}`;
}
