import { ObjectUtils, StringUtils } from "pangju-utils";

export class TokenUtils {
  static getToken() {
    return ObjectUtils.defaultIfNull(localStorage.getItem("token"), "");
  }

  static getUsername() {
    return StringUtils.defaultString(localStorage.getItem("username"));
  }

  static isAdmin() {
    return StringUtils.equalsIgnoreCase(localStorage.getItem("role"), "admin");
  }

  static setToken(token) {
    localStorage.setItem("token", token);
  }

  static setUser(username, role) {
    localStorage.setItem("username", username);
    localStorage.setItem("role", role);
  }

  static clear() {
    localStorage.clear();
  }
}
